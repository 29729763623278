import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from '@tanstack/react-router'
import clsx from 'clsx'
import React, { type ReactElement, useEffect, useRef, useState } from 'react'
import { Tooltip } from '../../Global/Elements/Tooltip/Tooltip'
import {
  item,
  itemTrigger,
  itemTriggerActive,
  subItemBeta,
  subItemIcon,
  subitem,
  submenu,
  subtitle
} from './GlobalMenu.css'
import type { MenuItem, MenuItemPrimary } from './menu'

interface GlobalMenuItemProps {
  menuItem: MenuItemPrimary
  currentSection: string | null
  legacyUrl?: string | null
}

export const GlobalMenuItem = ({
  menuItem,
  currentSection,
  legacyUrl
}: GlobalMenuItemProps): ReactElement => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const [timer, setTimer] = useState<number | null>(null)
  const submenuRef = useRef<HTMLDivElement | null>(null)

  useEffect((): void => {
    const current = submenuRef.current
    if (
      isOpen &&
      current !== null &&
      current.getBoundingClientRect().bottom > window.innerHeight
    ) {
      current.style.top = 'auto'
      current.style.bottom = '0px'
    } else if (isOpen && current !== null) {
      current.style.bottom = 'auto'
      current.style.top = '-40px'
    }
  }, [isOpen])

  const onOpening = (): void => {
    setOpen(true)
    if (timer !== null) {
      window.clearTimeout(timer)
    }
  }

  const onClosing = (): void => {
    setTimer(
      window.setTimeout(() => {
        setOpen(false)
      }, 100)
    )
  }

  const buildLinkItem = (menuItem: MenuItem, subItem = false) => {
    let LinkItem = (
      <a
        className={
          subItem
            ? subitem
            : clsx(
                itemTrigger,
                isOpen || currentSection === menuItem.id
                  ? itemTriggerActive
                  : null
              )
        }
        href={
          (!menuItem.v3 ? legacyUrl ?? '' : window.JobConfig?.app_url ?? '') +
          (menuItem.url ?? '#') +
          (menuItem.v3 && window.JobConfig
            ? `?jwt=${window.JobConfig.user.jwt}&instance=${window.JobConfig.instance.computedName}`
            : '')
        }
        title={menuItem.label}
      >
        {menuItem.isBeta && <span className={subItemBeta}>Beta</span>}
        {menuItem.icon !== undefined && (
          <FontAwesomeIcon
            icon={menuItem.icon}
            className={subItem ? subItemIcon : undefined}
          />
        )}
        {subItem && menuItem.label}
      </a>
    )

    if (menuItem.v3 && window.JobConfig === undefined) {
      LinkItem = (
        <Link
          to={menuItem.url ?? '#'}
          title={menuItem.label}
          className={
            subItem
              ? subitem
              : clsx(
                  itemTrigger,
                  isOpen || currentSection === menuItem.id
                    ? itemTriggerActive
                    : null
                )
          }
        >
          {menuItem.isBeta && <span className={subItemBeta}>Beta</span>}
          {menuItem.icon !== undefined && (
            <FontAwesomeIcon
              icon={menuItem.icon}
              className={subItem ? subItemIcon : undefined}
            />
          )}
          {subItem && menuItem.label}
        </Link>
      )
    }

    return LinkItem
  }

  return (
    <div className={item}>
      {menuItem.submenu !== undefined && (
        <div onMouseEnter={onOpening} onMouseLeave={onClosing}>
          {buildLinkItem(menuItem)}
          {isOpen && (
            <div className={submenu} ref={submenuRef} key={menuItem.id}>
              <div className={subtitle}>{menuItem.label}</div>
              {menuItem.submenu.map((sub) => buildLinkItem(sub, true))}
            </div>
          )}
        </div>
      )}
      {menuItem.submenu === undefined && (
        <Tooltip direction="right" text={menuItem.label}>
          {buildLinkItem(menuItem)}
        </Tooltip>
      )}
    </div>
  )
}
