import { faCheck, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { type ReactElement } from 'react'
import { StandardModal } from '~/components/Global/Design/Modal/StandardModal'
import { Button } from '../Button/Button'
import { ButtonVariation } from '../Button/ButtonVariation'

interface ConfirmProps {
  onConfirm: () => void
  onCancel: () => void
  show: boolean
  title?: string
  content?: string
  textConfirm?: string
}

export const Confirm = ({
  onConfirm,
  onCancel,
  show,
  title = 'Confirmation',
  content = '',
  textConfirm = 'Confirmer'
}: ConfirmProps): ReactElement => (
  <StandardModal
    open={show}
    onClose={onCancel}
    title={title}
    icon={faTriangleExclamation}
    buttons={[
      <Button onClick={onCancel} variation={ButtonVariation.Link} key="cancel">
        Annuler
      </Button>,
      <Button
        onClick={onConfirm}
        variation={ButtonVariation.Danger}
        key="confirm"
      >
        <FontAwesomeIcon icon={faCheck} />
        {textConfirm}
      </Button>
    ]}
  >
    {content}
  </StandardModal>
)
