import { zodResolver } from '@hookform/resolvers/zod'
import React, { type ReactElement } from 'react'
import {
  type DefaultValues,
  type FieldValues,
  type SubmitErrorHandler,
  type SubmitHandler,
  useForm
} from 'react-hook-form'
import type z from 'zod'
import { formClass } from './Form.css'
import { GlobalAlert } from './GlobalAlert'
import { Submit } from './Submit'

interface FormProps<T extends FieldValues> {
  children: ReactElement | ReactElement[]
  defaultValues?: DefaultValues<T>
  onSubmit: SubmitHandler<T>
  onError?: SubmitErrorHandler<T>
  globalError?: null | string
  globalErrorInSubmit?: boolean
  schema?: z.Schema<T>
}

export const Form = <T extends FieldValues>({
  defaultValues,
  children,
  onSubmit,
  onError,
  globalError,
  globalErrorInSubmit = false,
  schema
}: FormProps<T>): ReactElement => {
  if (children === undefined || children === null) {
    throw new Error('Form must have children')
  }
  const methods = useForm({
    defaultValues,
    resolver: schema !== undefined ? zodResolver(schema) : undefined
  })
  const { handleSubmit, formState } = methods
  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className={formClass}>
      {recursiveMap(children, (child: ReactElement) => {
        if (child?.type === Submit) {
          return React.createElement(child.type, {
            ...{
              ...child.props,
              isSubmitting: formState.isSubmitting,
              isSubmitSuccessful: formState.isSubmitSuccessful,
              globalError,
              globalErrorInSubmit,
              key: 'submit'
            }
          })
        }
        if (child?.type === GlobalAlert) {
          return React.createElement(child.type, {
            ...{
              ...child.props,
              error:
                globalError !== '' &&
                (!globalErrorInSubmit || !formState.isSubmitSuccessful)
                  ? globalError
                  : ''
            }
          })
        }
        if (child?.props?.name !== undefined) {
          return React.createElement(child.type, {
            ...{
              ...child.props,
              error: formState.errors[child.props.name],
              register: methods.register,
              control: methods.control,
              setValue: methods.setValue,
              watch: methods.watch,
              key: child.props.name
            }
          })
        }
        return child
      })}
    </form>
  )
}
const recursiveMap = (
  children: ReactElement[]|ReactElement,
  fn: (child: ReactElement) => ReactElement
): ReactElement[] => {
  return React.Children.map(children, child => {
    if (!React.isValidElement(child)) {
      return child;
    }

    if ((child as ReactElement).props.children) {
      const props = {
        children: recursiveMap((child as ReactElement).props.children, fn)
      }
      child = React.cloneElement(child, props);
    }

    return fn(child);
  });
}
