import { AnimatePresence, motion } from 'framer-motion'
import React, {
  type PropsWithChildren,
  type ReactElement,
  useCallback,
  useEffect
} from 'react'
import { Overlay } from '../Overlay/Overlay'
import { container, position } from './Modal.css'

export type modalProps = PropsWithChildren<{
  open: boolean
  onClose: () => void
  customPositionClass?: string
}>

export const Modal = ({
  open,
  onClose,
  customPositionClass,
  children
}: modalProps): ReactElement => {
  const handleGlobalShortCut = useCallback(
    (e: KeyboardEvent): void => {
      if (e.key === 'Escape') {
        onClose()
      }
    },
    [onClose]
  )
  useEffect(() => {
    window.addEventListener('keydown', handleGlobalShortCut)
    return (): void => {
      window.removeEventListener('keydown', handleGlobalShortCut)
    }
  })
  return (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Overlay onClick={onClose} />
          <div className={customPositionClass ?? position}>
            <div className={container}>{children}</div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
